import React from 'react';
import Explore from "./shared/explore";
import {Helmet, HelmetProvider} from "react-helmet-async";

function AboutUs(props) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>About us | SolarMax Technology</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/about-us`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../assets/media/pictures/static/1. Hero HQ.jpg')}')`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-1">We are SolarMax</div>
                            <div className="single__hero_desc text-heading-5">Customer satisfaction
                                is our business model</div>
                            <div className="single__hero_cta">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about__introduction">
                <div className="container_s">
                    <div className="about__introduction_wrapper">
                        <div className="about__introduction_text">
                            <div className="about__introduction_title text-heading-3">How we do business</div>
                        </div>
                        <div className="about__introduction_elements">
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Putting customers first</div>
                                <div className="about__element_icon"><i className="ri-user-heart-fill"></i></div>
                                <div className="about__element_desc text-body-1">
                                    We don't just meet customer needs; we want to exceed their expectations.
                                </div>
                            </div>
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Striving for excellence</div>
                                <div className="about__element_icon"><i className="ri-dashboard-3-fill"></i></div>
                                <div className="about__element_desc text-body-1">
                                    We are dedicated to providing top quality products and unparalleled performance.
                                </div>
                            </div>
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Being competitive</div>
                                <div className="about__element_icon"><i className="ri-flag-2-fill"></i></div>
                                <div className="about__element_desc text-body-1">
                                    We can be pretty competitive; our great prices prove it.
                                </div>
                            </div>
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Staying adaptable</div>
                                <div className="about__element_icon"><i className="ri-drag-move-2-line"></i></div>
                                <div className="about__element_desc text-body-1">
                                    The solar industry is constantly changing, so we keep our tech at the edge of innovation.
                                </div>
                            </div>
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Empowering others</div>
                                <div className="about__element_icon"><i className="ri-speak-fill"></i></div>
                                <div className="about__element_desc text-body-1">
                                    We believe in sharing knowledge, resources, and opportunities to help everyone benefit from clean energy.
                                </div>
                            </div>
                            <div className="about__introduction_element">
                                <div className="about__element_title text-heading-5">Sticking to our word</div>
                                <div className="about__element_icon"><i className="ri-earth-fill"></i></div>
                                <div className="about__element_desc text-body-1">
                                    Our systems perform consistently at a high level throughout their life or we pay you.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="about__banner">
                <div className="container_s">
                    <div className="about__banner_wrapper"></div>
                </div>
            </div>

            <div className="about__numeric">
                <div className="container_s">
                    <div className="about__numeric_wrapper">
                        <div className="about__numeric_text">
                            <div className="about__numeric_title text-heading-2">15,000+ projects completed</div>
                            <div className="about__numeric_desc text-body-1">We have been setting the benchmark for quality at competitive prices in the solar industry for nearly two decades. But no matter how big we get, providing friendly, local customer service is still our top priority.</div>
                        </div>
                        <div className="about__numeric_numbers">
                            <div className="about__numeric_number">
                                <div className="about__number_title text-heading-4">25 years</div>
                                <div className="about__number_desc text-body-1">product and workmanship warranties</div>
                            </div>
                            <div className="about__numeric_number">
                                <div className="about__number_title text-heading-4">25 million</div>
                                <div className="about__number_desc text-body-1">tons of carbon emissions prevented</div>
                            </div>
                            <div className="about__numeric_number">
                                <div className="about__number_title text-heading-4">16 years</div>
                                <div className="about__number_desc text-body-1">providing exceptional service to clients</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="financing__options">
                <div className="container_s">
                    <div className="financing__options_wrapper">
                        {/*<div className="financing__options_text">*/}
                        {/*    <div className="financing__options_title text-heading-5">Save Now with Special Financing</div>*/}
                        {/*    <div className="financing__options_desc text-heading-6">Affordable solar options with attractive financing for Southern California residents and businesses.</div>*/}

                        {/*</div>*/}
                        <div className="financing__options_elements">
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    <img src={require('../assets/media/pictures/static/2.1 IPO.jpg')} alt=""/>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Going IPO</div>
                                    <div className="financing__element_desc text-body-1">We are proud to announce we are now listed on the NASDAQ stock exchange</div>

                                </div>
                            </div>
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    <img src={require('../assets/media/pictures/static/2.2 Historic HQ.png')} alt=""/>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Our historic building</div>
                                    <div className="financing__element_desc text-body-1">Long before we took it over, the iconic SolarMax headquarters in Riverside California had quite a bit of history. Here is the story</div>

                                </div>
                            </div>
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    <img src={require('../assets/media/pictures/static/2.3 Agrivoltaics.jpg')} alt=""/>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Greener than green</div>
                                    <div className="financing__element_desc text-body-1">We are one of the few US companies focusing on integrating solar solutions into farms. Read about how we are making those leafy greens even greener</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Explore explore={props.explore}/>
        </>
    );
}

export default AboutUs;