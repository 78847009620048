import React from 'react';

function SoSpecial(props) {
    return (
        <div>
            <div className="container">
                <div className="page-header ">

                    <h1>Home Solar Systems Starting at Less Than $40/month</h1>


                </div>
            </div>

            <div className="container container-page">


                <div className="row" itemProp="makesOffer" itemScope itemType="http://schema.org/Offer" style={{display: 'flex'}}>
                    <div className="col-md-6 format-page" style={{width:'50%'}}>
                        <div className="offer-hero">
                            <img itemProp="image" className="img-responsive"
                                 src="/assets/media/pictures/wwwsolarmaxtechcom/offers/images/large/9096f53e-b5bb-4ed9-8b1a-3ecdf9838af5.jpg"
                                 alt="Home Solar Systems Starting at Less Than $40/month"/>
                        </div>

                        <div className="page-content"><p>Save up to 60% each month off your energy bill and save now
                            with a high-quality
                            home solar energy system. Save now with limited-time special offers + federal tax incentives
                            +
                            California solar energy storage incentive, and continue to benefit from long-term energy
                            savings each
                            month.</p>
                            <h2>Home Solar System from $39.83/month</h2>
                            <ul>
                                <li><span style="text-align: -webkit-match-parent;">2.4 KW</span></li>
                                <li><span style="text-align: -webkit-match-parent;">(6) Talesun 400W Solar Panels</span><br/>
                                </li>
                                <li>(6) Enphase IQ8+ Microinverters</li>
                            </ul>
                            <h2>Save Now with Special Financing</h2>
                            <p>See if you qualify for special low-APR financing to save you even more:<br/></p>
                            <ul>
                                <li>$39.83 monthly* (with 30% tax credit)<br/></li>
                                <li>$0 Down - pay nothing now<br/></li>
                                <li>25 year financing as low as 3.99%</li>
                            </ul>
                            <h2>Solar Tax Credit Savings</h2>
                            <p>The solar tax credit is at the highest rate ever - 30% - start saving big! Schedule your
                                consultation
                                today!</p>
                            <p><br/></p>
                            <p><em>*Terms and Conditions: Financing is available on approved credit. Monthly payment
                                amount assumes
                                the 30% tax credit is applied. Payment increases to $56.99 if 30% ITC is not applied by
                                the 16th of
                                the month. $0 down payment required with a payment within 16 months equal to the 30%
                                federal
                                renewable energy tax credit (ITC) required. Limited availability – act now, while
                                supplies
                                last.</em></p></div>


                    </div>

                    <div className="col-md-6 position-sticky side-bar" style={{width:'50%'}}>


                        <div id="quote-form" className="quote-form panel panel-quote-form">
                            <div className="panel-body">
                                <div className="section-header">
                                    <p className="h2">Get a Free Solar Savings Analysis!</p>
                                    <div className="lead"></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 credibility text-center">
                                        <div className="item">
                                            <div className="aggregate-reviews ">
                                                <div className="credibility-description">
                                                    <div className="review-stars">
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span>
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg fa-star-half-o"></span>
                                                    </div>
                                                </div>

                                                <div className="value">
                                                    <div className="rating">
                                                        <span className="average">4.8</span> out of <span
                                                        className="best">5</span>
                                                    </div>
                                                    based on <span className="votes">45</span> Reviews
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item"><img className="img-responsive center-block"
                                                                   src="../../img/page/logo-warranty-panels-25.png"
                                                                   alt="Warranty on Panels"/></div>
                                        <div className="item"><img className="img-responsive center-block"
                                                                   src="../../img/page/logos/workmanship-warranty.png"
                                                                   alt="Workmanship Warranty"/></div>
                                        <div className="item"><img className="img-responsive center-block"
                                                                   src="../../img/page/logo-warranty-inverters.png"
                                                                   alt="Warranty on Inverters"/></div>
                                    </div>

                                    <div className="col-sm-7">


                                        <form className="mb-1" id="price-quote"
                                              action="https://www.solarmaxtech.com/quote-thank-you/?ref=pricing"
                                              method="post"
                                              role="form">
                                            <div className="form-group">
                                                <label htmlFor="price-quote-name" className="sr-only">What is your
                                                    name?</label>
                                                <input id="price-quote-name" name="name" className="form-control"
                                                       type="text"
                                                       placeholder="Full Name" maxLength="50"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-email" className="sr-only">What is your
                                                    email address?</label>
                                                <input id="price-quote-email" name="email" className="form-control"
                                                       type="email"
                                                       placeholder="Email Address" maxLength="50"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-phone" className="sr-only">What is your
                                                    phone number?</label>
                                                <input id="price-quote-phone" name="phone" className="form-control"
                                                       type="tel"
                                                       placeholder="Phone Number" maxLength="14"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-address" className="sr-only">Whats your full
                                                    address?</label>
                                                <input id="price-quote-address" name="address" className="form-control"
                                                       type="text"
                                                       placeholder="Full Address" autoComplete="off"/>
                                                <input id="g-address1" name="address1" type="hidden"
                                                       autoComplete="off"/>
                                                <input id="g-address2" name="address2" type="hidden"
                                                       autoComplete="off"/>
                                                <input id="g-city" name="city" type="hidden" autoComplete="off"/>
                                                <input id="g-state" name="state" type="hidden" autoComplete="off"/>
                                                <input id="g-zip" name="zipcode" type="hidden" autoComplete="off"/>
                                                <input id="g-country" name="country" type="hidden" autoComplete="off"/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="price-quote-project" className="sr-only">What type of
                                                    project are you
                                                    interested in?</label>
                                                <select id="price-quote-project" name="service"
                                                        className="form-control">
                                                    <option value="" selected="selected">Project Type</option>
                                                    <option value="Residential Solar Energy">Residential Solar Energy
                                                    </option>
                                                    <option value="Commercial Solutions">Commercial Solutions</option>
                                                    <option value="LED Lighting">LED Lighting</option>
                                                    <option value="Roof Replacement">Roof Replacement</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="price-quote-details" className="sr-only">Please provide
                                                    a description of
                                                    your project.</label>
                                                <div className="controls">
                                        <textarea id="price-quote-details" name="details" className="form-control"
                                                  placeholder="Project Description" rows="3"
                                                  maxLength="3000"></textarea>
                                                </div>
                                            </div>
                                            <input id="price-quote-type" name="type" type="hidden" value="Quote"/>
                                            <input id="quoteToken" name="quoteToken" type="hidden" value=""/>
                                            <input id="variantPage" type="hidden" data-value="false"
                                                   data-phone-trackable=""
                                                   data-phone-id="" value=""/>
                                            <input id="check" name="spamCheck" className="covered" value=""/>
                                            <button id="price-quote-submit"
                                                    className="btn btn-block btn-lg btn-primary submit-form"
                                                    type="submit">
                                                Get Free Quote
                                            </button>
                                        </form>
                                        <p><small>Your personal information is never distributed or sold to anyone. You
                                            can opt-out
                                            of communications at any time. View our <a
                                                href="../../privacy-policy/index.html">privacy
                                                policy</a>.</small></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <input id="input-offerid" type="hidden" name="offerid"
                                                     value="48a9f4de-1b01-4dca-afe8-0f9a189cd430"/>

                        <div className="panel panel-default">
                            <div className="panel-heading">Share</div>
                            <div className="panel-body">
                                <div className="btn-group-social-media">
                                    <a className="btn btn-facebook"
                                       href="https://www.facebook.com/sharer/sharer.php?u=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fsolar-special%2f"
                                       target="_blank">
                                        <span className="sr-only">Share on Facebook</span>
                                        <i className="fa fa-facebook fa-fw"></i>
                                    </a>
                                    <a className="btn btn-twitter"
                                       href="https://twitter.com/home?status=Thought%20you%20might%20like%20this%20http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fsolar-special%2f"
                                       target="_blank">
                                        <span className="sr-only">Share on Twitter</span>
                                        <i className="fa fa-twitter fa-fw"></i>
                                    </a>
                                    <a className="btn btn-linkedin"
                                       href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fsolar-special%2f"
                                       target="_blank">
                                        <span className="sr-only">Share on LinkedIn</span>
                                        <i className="fa fa-linkedin fa-fw"></i>
                                    </a>
                                    <a className="btn btn-pinterest"
                                       href="https://pinterest.com/pin/create/button/?url=http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fsolar-special%2f&amp;media=https%3a%2f%2fremodelerplatform.blob.core.windows.net%2fwwwsolarmaxtechcom%2foffers%2fimages%2flarge%2f9096f53e-b5bb-4ed9-8b1a-3ecdf9838af5.jpg&amp;description=Home%20Solar%20Systems%20Starting%20at%20Less%20Than%20$40/month"
                                       target="_blank">
                                        <span className="sr-only">Share on Pinterest</span>
                                        <i className="fa fa-pinterest fa-fw"></i>
                                    </a>
                                    <a className="btn btn-warning"
                                       href="mailto:?subject=Thought%20you%20might%20like%20this%20from SolarMax%20Technology%20&body=Check%20this%20out%20from SolarMax%20Technology http%3a%2f%2fwww.solarmaxtech.com%2foffers%2fsolar-special%2f"
                                       target="_blank">
                                        <span className="sr-only">Share via Email</span>
                                        <i className="fa fa-envelope fa-fw"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="breadcrumbs">
                <div className="container">
                    <ol className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../../index.html">
                            <span className="hidden-lg">
                                <i className="fas fa-home"></i>
                            </span>
                                <span className="visible-lg" itemProp="name">Home</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../index.html">
                                <span itemProp="name">Offers</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a href="index.html" itemProp="url">
                                <span itemProp="title">Home Solar Systems Starting at Less Than $40/month</span>
                            </a>

                        </li>

                    </ol>
                </div>
            </div>
        </div>
    );
}

export default SoSpecial;