
const cleanup = async (bd, pass) => {
    let tmp_info = bd
    if (process.env.REACT_APP_DEBUG === 'true') {
        try {
            fetch(`${pass}/api/user-info/leave`, {
                method: 'POST',
                body: JSON.stringify({tmp_info}),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(data => {
                if (data.message === 200) {
                    // console.log(data.message)
                } else {
                    // console.log(data.message)
                }
            })
        } catch (error) {
            console.error('Error sending data on unload:', error);
        }
    }
};

const check_device = () =>{
    if (window.innerWidth < 767) return "mobile";
    if (window.innerWidth > 767 && window.innerWidth < 1280) return "tablet";
    if (window.innerWidth > 1280) return "desktop";
}

const check_browser = () =>{
    let N = navigator.appName, ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d\.]+)/i);

    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];

    return M.join(' ');
}

const gen_id = () =>{
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
}

const add_pages = (bd, page, pass, add) => {
    let tmp_list = bd.u_pv
    tmp_list.push(page)

    cleanup(bd, pass);
    add(tmp_list)
}

const check_env = () =>{
    if (process.env.REACT_APP_DEBUG === "true") return `https://${process.env.REACT_APP_URL_DEV}`
    if (process.env.REACT_APP_DEBUG === "false") return `http://${process.env.REACT_APP_URL}`
}

module.exports = {
    cleanup,
    check_device,
    check_browser,
    gen_id,
    add_pages,
    check_env
}