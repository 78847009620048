import React, {useEffect, useState} from 'react';
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import {Form, Link, useLocation} from "react-router-dom";
import Explore from "./shared/explore";
import Pagination from "../views/includes/pagination";
import {Helmet, HelmetProvider} from "react-helmet-async";
function ArticlesHome(props) {
    const [postLoader, setPostLoader] = useState(true)

    const location = useLocation();

    const [path, setPath] = useState(location.pathname)

    const [allPosts, setAllPosts] = useState(null)

    const [recentPosts, setRecentPosts] = useState(null)

    const [searchInput, setSearchInput] = useState(null)

    const [pagination, setPagination] = useState({
        items: 8,
        pages: null
    })

    const [currentPage, setCurrentPage] = useState(1)

    const [openForm, setOpenForm] = useState(false)

    const [subEmail, setSubEmail] = useState(null)

    const [subscriptionSubmit, setSubscriptionSubmit] = useState(false)

    const [error, setError] = useState('')

    const handleSubscription = (e) =>{
        e.preventDefault()
        if (!subEmail){
            setError('Please add the proper email')
        }else if(!subEmail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            setError('Please add the proper email')
        }else{
            setError('')
            fetch(`${props.debugValue}/api/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({u_data: subEmail ? subEmail : '' , u_br: props.bd.u_br, u_os: props.bd.u_os, u_id: props.bd.u_id})
            }).then(()=>setSubscriptionSubmit(!subscriptionSubmit)).catch(err=>console.log(err))
        }
    }

    const handleEmail = (e) =>{
        setSubEmail(e.target.value)
    }

    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(()=>{
        if(location.pathname.split('/').length > 3){
            window.location.href = '/blog'
        }
        setPostLoader(true)
        fetch(`${props.debugValue}/api/blogs-all/${currentPage}`).then(res=>res.json()).then(data=> {
            setAllPosts(data.all)

            setRecentPosts(data.recent)

            setPagination(old=> ({
                ...old,
                pages: data.pages
                })
            )
            setTimeout(()=>{
                setPostLoader(false)

            },1000)

        }).catch(err=>console.log(err))
    }, [])

    useEffect(()=>{
        setPostLoader(true)
        fetch(`${props.debugValue}/api/blogs-all/${currentPage}`).then(res=>res.json()).then(data=>{
            setAllPosts(data.all)
            setPostLoader(false)
        }).catch(err=>console.log(err))

    }, [currentPage])

    const handleNextPagination = async (e) =>{
        window.scrollTo({top: 0, behavior: 'smooth'})

        if (currentPage){
            setCurrentPage(currentPage + 1)
        }
    }

    const handleBeforePagination = async (e) =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
        if (currentPage > 1 || currentPage !== 1){
            setCurrentPage(currentPage - 1)
        }
    }

    const handleSearchInput = (e) =>{
        let searchValue = e.target.value.trim().toLowerCase()
        setPostLoader(true)

        setSearchInput(e.target.value.toLowerCase())

        if (searchValue){
            fetch(`${props.debugValue}/api/blogs-search/${searchValue}/${pagination.items}`).then(res=>res.json()).then(data => {
                setAllPosts(data.all)
                setPagination(old=>({
                        ...old,
                        pages: data.pages
                    })
                )
                setPostLoader(false)
            }).catch(err=>console.log(err))
        }else{
            fetch(`${props.debugValue}/api/blogs-all/${pagination.items}`).then(res=>res.json()).then(data=> {
                setAllPosts(data.all)
            }).catch(err=>console.log(err))
        }
    }

    const handleForm = () =>{
        setOpenForm(!openForm)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Blog</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/blog`}/>
                </Helmet>
            </HelmetProvider>
            <section className="articles__hero">
            <div className="">
                    <div className="articles__hero_wrapper">
                        <div className="articles__hero_pages">
                            <Splide aria-label="" hasTrack={false}
                                    options={{
                                        perPage: 1,
                                        arrows: false,
                                        type: 'loop',
                                        autoplay:true,
                                        resetProgress: false
                                    }}>
                                <SplideTrack>
                                    <SplideSlide style={{backgroundImage: `url('${require('../assets/media/pictures/static/articles/blogs-decarbonization.webp')}')`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__title text-heading-2">
                                                    Get our solar newsletter
                                                </div>
                                                <div className="slide__desc text-heading-6">
                                                    Stay informed about industry news, government incentives, and our special offers by subscribing to our monthly solar newsletter
                                                </div>
                                                <div className="slide__cta text-heading-7">
                                                    <button onClick={handleForm} className={'text-heading-7'}>Subscribe now</button>
                                                </div>
                                            </div>
                                            <div className="slide__event_wrapper">
                                                <div className="slide__event" style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(5, 17, 37, 0.90) 67.87%), url(${require('../assets/media/pictures/static/articles/blogs-event-1.jpeg')}) lightgray 50% / cover no-repeat`}}>
                                                    <div className="slide__event_text">
                                                        <div className="slide__event_date text-heading-6"><i
                                                            className="ri-circle-fill"></i> 09 May, 2024</div>
                                                        <div className="slide__event_title text-heading-3"><a download
                                                            href="../assets/media/documents/SolarMax_Carport_Opportunity_Deck_20240509 (2).pdf">Decarbonize LA</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide style={{backgroundImage: `url('${require('../assets/media/pictures/static/articles/blogs_ipo.jpg')}')`,backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__title text-heading-2">
                                                    Looking to invest?
                                                </div>
                                                <div className="slide__desc text-heading-6">
                                                    Our investor relations section has all the latest information about our company operations and stock info
                                                </div>
                                                <div className="slide__cta text-heading-7">
                                                    <a target={'_blank'} href="https://irsites.com/smxt/">View investor relations</a>
                                                </div>
                                            </div>
                                            <div className="slide__event_wrapper">
                                                <div className="slide__event" style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(5, 17, 37, 0.90) 67.87%), url(${require('../assets/media/pictures/static/articles/blogs-event-1.jpeg')}) lightgray 50% / cover no-repeat`}}>
                                                    <div className="slide__event_text">
                                                        <div className="slide__event_date text-heading-6"><i
                                                            className="ri-circle-fill"></i> 27 Feb, 2024</div>
                                                        <div className="slide__event_title text-heading-3"><a
                                                            href="https://www.linkedin.com/posts/solarmax-technology_behind-the-bell-solarmax-technology-inc-activity-7194405223140900865-7ijY">SolarMax IPO</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </SplideTrack>
                                <div className="splide__progress">
                                    <div className="splide__progress__bar"/>
                                </div>
                            </Splide>
                            <div style={{position: "fixed", top: '0', maxWidth: '1440px', zIndex: '11'}} className={`get__quote ${openForm? 'get__quote_active' : ''}`}>
                                <div className={`get__quote_form_wrapper`} style={{top: '100px', height: '409px'}}>
                                    <div className="get__quote_form">
                            {subscriptionSubmit ?
                                <>
                                    <div className={'text-heading-3'} style={{textAlign: "center", margin: '12px 16px'}}>Thank you!</div>
                                    <button onClick={event => handleForm(event)} style={{background: 'var(--branding-secondary)', border: '1px solid var(--branding-secondary)', width: '100%', borderRadius: '4px', padding: '8px 24px'}} className={'text-heading-7'}>Close</button>

                                </>
                                :
                                <>
                                    {/*<div className={'text-heading-6'}>Our solar energy experts will reach out to you by phone or email shortly.</div>*/}
                                    <div className={'text-heading-6'} style={{textAlign: "center", margin: '12px 16px'}}>The latest news</div>
                                    <p className={'text-body-1'}>Stay informed about industry news, government incentives, and our special offers by subscribing to our monthly solar newsletter.</p>
                                    <Form onSubmit={event => handleSubscription(event)} style={{margin: '24px 0 16px 0'}}>
                                        <input onChange={event => handleEmail(event)} type="email" style={{width: '100%', padding: "9px 16px", borderRadius: "4px", outline: "none", border: '1px solid gray'}} value={subEmail? subEmail : ''}/>
                                        <span style={{color: "red"}} className={'text-body-3'}>{error && error}</span>
                                        <div style={{marginTop: '16px', display: "flex", gap: '16px'}}><button type={"submit"} className={'text-heading-7'} style={{border: "none",flexGrow: 1, background: 'var(--button-primary)', padding: '8px 24px', borderRadius: '4px', cursor: "pointer"}}>Yes, sign up me up!</button><div className={'text-heading-7'} style={{border: "none",flexGrow: 1, textAlign:"center", background: 'var(--button-custom)', padding: '7px 24px', borderRadius: '4px', cursor: "pointer"}} onClick={handleForm}>No thanks</div></div>
                                    </Form>
                                    <p className={'text-body-3'}>We won't share your email with anyone, and you can unsubscribe at any time. Learn more in our <Link style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy policy</Link>.</p>
                                </>}
                                    </div>
                                </div>
                                <div className="get__quote_wrapper" onClick={handleForm}></div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="all__blogs">
                <div className="container_s">
                    <div className="all__blogs_wrapper">
                        <div className="blogs__navigation_search blogs__search_mobile">
                            <Form onSubmit={event => event.preventDefault()} action="">
                                <div className="blogs__search_wrapper">
                                    <input value={searchInput? searchInput : ''} placeholder={'Search here'} onChange={event => handleSearchInput(event)} type="text"/>
                                    <button><i className="ri-search-2-line"></i></button>
                                </div>
                            </Form>
                        </div>
                        <div className="current__blogs">
                            <div className="current__blogs_wrapper">
                                {postLoader &&
                                    <>
                                        <div className="cards blog">
                                            <div className="card_image loading"></div>
                                            <div className="card_title loading"></div>
                                            <div className="card_description loading"></div>
                                        </div>
                                        <div className="cards blog">
                                            <div className="card_image loading"></div>
                                            <div className="card_title loading"></div>
                                            <div className="card_description loading"></div>
                                        </div>
                                        <div className="cards blog">
                                            <div className="card_image loading"></div>
                                            <div className="card_title loading"></div>
                                            <div className="card_description loading"></div>
                                        </div>
                                        <div className="cards blog">
                                            <div className="card_image loading"></div>
                                            <div className="card_title loading"></div>
                                            <div className="card_description loading"></div>
                                        </div>
                                    </>
                                }
                                {allPosts && !postLoader && allPosts.map(post => {
                                    return (
                                        <div key={post.path + window.crypto.getRandomValues(new Uint32Array(10))}
                                             className="blog">
                                        <Link onClick={handleLink}
                                                  to={`${post.category === 'location' ? '/locations' : '/blog'}${post.path.replaceAll('^=39', '')}`}>
                                                <div className="blog__image">
                                                    {post.cover_img ? <img src={post.cover_img} alt=""/> : ''}
                                                    </div>
                                                    <div className="blog__text">
                                                        <div className="blog__details text-body-3">
                                                            Post by <span>{post.author}</span><i
                                                            className="ri-circle-fill"></i> {new Date(post.created_at).toLocaleDateString("en-US", {day: 'numeric', month: 'long'})}
                                                        </div>
                                                        <div className="blog__title text-heading-6">{post.title ? post.title.replaceAll('^=39', '\'') : ''}</div>
                                                        <div className="blog__desc text-heading-6">{post.cover_content ? post.cover_content.replaceAll('^=39', '\'').replaceAll('col-md-6', '') : ''}...</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={'mt-2'}>{pagination.pages && <Pagination currentPage={currentPage} pages={pagination.pages} handleBeforePagination={handleBeforePagination} handleNextPagination={handleNextPagination}/>}</div>

                        </div>
                        <div className="blogs__navigation">
                            <div className="blogs__navigation_wrapper">
                                <div className="blogs__navigation_search">
                                    <Form onSubmit={event => event.preventDefault()} action="">
                                        <div className="blogs__search_wrapper">
                                            <input placeholder={'Search here'} onKeyDown={event => handleSearchInput(event)} type="text"/>
                                            <button><i className="ri-search-2-line"></i></button>
                                        </div>
                                    </Form>
                                </div>
                                {!postLoader &&
                                    <div className="latest__blogs_wrapper">
                                        <div className="latest__blogs_title text-body-1">
                                            Recent articles
                                        </div>
                                        <div className="latest__blogs">
                                            {recentPosts &&
                                                recentPosts.map(recent=>{
                                                    return(
                                                        <div key={recent.path+window.crypto.getRandomValues(new Uint32Array(10))} className="latest__blog">
                                                            <Link onClick={handleLink} to={`/blog${recent.path}`}>
                                                                <div className="latest__blog_img">
                                                                    <img src={recent.cover_img} alt=""/>
                                                                </div>
                                                                <div className="latest__blog_text">
                                                                    <div className="latest__blog_title text-heading-6">
                                                                        {recent.title ? recent.title.replaceAll('^=39', '\'') : ''}
                                                                    </div>
                                                                    <div className="latest__blog_details text-heading-6">
                                                                        <p>{new Date(recent.created_at).toLocaleDateString("en-US", {day: 'numeric', month: 'long', year: 'numeric'})}</p><p className={'text-heading-7'} style={{color: '#ECBC14'}}>by {recent.author}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {postLoader &&
                                    <div className="latest__blogs_wrapper">
                                        <div className="latest__blogs_title text-body-1">
                                            Recent articles
                                        </div>
                                        <>
                                            <div className="cards latest__blogs">
                                                <div className="card_image latest__blog_img loading"></div>
                                                <div className="card_title latest__blog_title loading"></div>
                                                <div className="card_description latest__blog_details loading"></div>
                                            </div>
                                            <div className="cards blog">
                                                <div className="card_image latest__blog_img loading"></div>
                                                <div className="card_title latest__blog_title loading"></div>
                                                <div className="card_description latest__blog_details loading"></div>
                                            </div>
                                        </>
                                    </div>
                                }
                                <div className="social__media_reference">
                                    <div className="social__media_title text-body-1">
                                        Connect with us
                                    </div>
                                    <div className="blogs__legal_sm">
                                        <div className="blogs__sm">
                                            <a href=""><i className="ri-facebook-circle-fill"></i></a>
                                        </div>
                                        <div className="blogs__sm">
                                            <a href=""><i className="ri-instagram-line"></i></a>
                                        </div>
                                        <div className="blogs__sm">
                                            <a href=""><i className="ri-twitter-x-line"></i></a>
                                        </div>
                                        <div className="blogs__sm">
                                            <a href=""><i className="ri-linkedin-box-fill"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Explore explore={props.explore}/>

            <section className="homepage__cta">
                <div className="container_s">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start today with a free quote from our solar experts
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable
                            prices. Enjoy easy financing options and low monthly payments that make reinforcing your
                            solar energy systems simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get your quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default ArticlesHome;