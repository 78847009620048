import '../../assets/css/shared.css'
import 'remixicon/fonts/remixicon.css'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useEffect, useState} from "react";
import HeaderLayout from "./header-layout";
import HomeMain from "../../components/home/home-main";
import BusinessOwnerBattery from "../../components/solutions/business-owner-battery";
import HomeOwnerBattery from "../../components/solutions/home-owner-battery";
import BusinessOwnerSolar from "../../components/solutions/business-owner-solar";
import HomeOwnerSolar from "../../components/solutions/home-owner-solar";
import Article from "../../components/article";
import ArticlesHome from "../../components/articles-home";
import Financing from "../../components/financing";
import Contact from "../../components/contact";
import SpecialOffers from "../../components/special-offers";
import Warranties from "../../components/warranties";
import FormComplete from "../../components/form-complete";
import Error from "../../components/error";
import AboutUs from "../../components/about-us";
import Locations from "../../components/locations";
import Reviews from "../../components/reviews";
import Roofing from "../../components/solutions/roofing";
import ReferralIntro from "../../components/referral/referral-intro";
import ReferralDashboard from "../../components/referral/referral-dashboard";
import ReferralConfirmation from "../../components/referral/referral-confirmation";
import FormEstimate from "../../components/form/form-estimate";
import SolarmaxVsTesla from "../solarmax_seo/solarmax-vs-tesla";
import Resources from "../solarmax_seo/resources";
import ElectricCarCharging from "../solarmax_seo/electric-car-charging";
import LedLighting from "../solarmax_seo/led-lighting";
import SolarTaxCredit from "../solarmax_seo/solar-tax-credit";
import VistaCieloHomes from "../solarmax_seo/vista-cielo-homes";
import SmxSolarPanels from "../solarmax_seo/smx-solar-panels";
import WhyChooseSolarmax from "../solarmax_seo/why-choose-solarmax";
import SoBattery from "../offers/so-battery";
import SoFinancing from "../offers/so-financing";
import SoSpecial from "../offers/so-special";
import SoSolarTaxCredit from "../offers/so-solar-tax-credit";
import {check_browser, check_device, check_env, gen_id} from "../../utils/informative";
import {gen_router_element} from "../../utils/gen-component";

const explorables = require("../../lib/explorables");
const blogs_p = require("../../lib/blogs-static-p.json");


const Main = () => {
    const [showForm, setShowForm] = useState(false)
    const [pass, setPass] = useState(null)
    const [staticBlogs, setStaticBlogs] = useState(null)
    const toggleForm = () => {
        setShowForm(!showForm)
    }
    const add_page = (tmp_list) => {
        setbd({...bd, u_pv: tmp_list});
    }

    const [bd, setbd] = useState({
        u_pv: [],
        u_br: null,
        u_os: null,
        u_id: null
    })

    useEffect(() => {
        setbd((old) => {
            let s = check_device()
            let b = check_browser()
            let id = gen_id()
            return {
                ...old,
                u_os: s,
                u_br: b,
                u_id: id
            }
        })

        let env = check_env()

        setPass(env)

        gen_router_element(blogs_p, {
            toggleForm: toggleForm,
            showForm: showForm,
            bd: bd,
            explore: [explorables.company, explorables.specials],
            debugValue: pass
        }).then(res => setStaticBlogs(res)).catch(err => console.log(err));
    }, [])


    const handleLink = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const router = createBrowserRouter([
        {path: '/form-complete', element: <FormComplete toggleForm={toggleForm} bd={bd}/>},
        {
            element: <HeaderLayout toggleForm={toggleForm} add_page={add_page} showForm={showForm} debugValue={pass}
                                   bd={bd}/>, children: [

                {
                    path: '*',
                    element: <Error bd={bd} debugValue={pass} toggleForm={toggleForm} handleLink={handleLink}/>
                },
                {staticBlogs},
                {
                    path: '/solarmax-vs-tesla',
                    element: <SolarmaxVsTesla toggleForm={toggleForm} bd={bd}/>,

                },

                {path: '/resources', element: <Resources toggleForm={toggleForm} bd={bd}/>},
                {
                    path: '/solar-products/electric-car-charging/',
                    element: <ElectricCarCharging toggleForm={toggleForm} bd={bd}/>
                },
                {
                    path: '/commercial/led-lighting',
                    element: <LedLighting toggleForm={toggleForm} bd={bd}/>
                },
                {
                    path: '/solar-tax-credit',
                    element: <SolarTaxCredit toggleForm={toggleForm} bd={bd}/>
                },
                {
                    path: '/vista-cielo-homes',
                    element: <VistaCieloHomes toggleForm={toggleForm} bd={bd}/>
                },
                {
                    path: '/solar-products/smx-solar-panels/',
                    element: <SmxSolarPanels toggleForm={toggleForm} bd={bd}/>
                },
                {
                    path: '/why-choose-solarmax',
                    element: <WhyChooseSolarmax toggleForm={toggleForm} bd={bd}/>
                },
                {path: '/', element: <HomeMain toggleForm={toggleForm} showForm={showForm} debugValue={pass}/>},
                {
                    path: '/businesses/battery',
                    element: <BusinessOwnerBattery toggleForm={toggleForm} handleLink={handleLink}
                                                   bd={bd} debugValue={pass}/>
                },
                {
                    path: '/businesses/solar',
                    element: <BusinessOwnerSolar toggleForm={toggleForm} handleLink={handleLink}
                                                 bd={bd} debugValue={pass}/>
                },
                {
                    path: '/homeowners/battery',
                    element: <HomeOwnerBattery toggleForm={toggleForm} bd={bd}
                                               handleLink={handleLink} debugValue={pass}/>
                },
                {
                    path: '/homeowners/solar',
                    element: <HomeOwnerSolar toggleForm={toggleForm} bd={bd}
                                             handleLink={handleLink} debugValue={pass}/>
                },
                {
                    path: '/roofing',
                    element: <Roofing toggleForm={toggleForm} bd={bd} debugValue={pass}/>
                },
                {
                    path: '/blog',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/blog/:path',
                    element: <Article toggleForm={toggleForm} bd={bd} showForm={showForm}
                                      debugValue={pass}/>
                },
                {
                    path: '/locations/:path',
                    element: <Article toggleForm={toggleForm} bd={bd} showForm={showForm}
                                      debugValue={pass}/>
                },
                {
                    path: '/financing',
                    element: <Financing toggleForm={toggleForm} bd={bd}
                                        explore={[explorables.warranties, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/warranties',
                    element: <Warranties toggleForm={toggleForm} bd={bd}
                                         explore={[explorables.specials, explorables.financing]} debugValue={pass}/>
                },
                {
                    path: '/contact-us',
                    element: <Contact toggleForm={toggleForm} bd={bd} debugValue={pass}/>
                },
                {
                    path: '/reviews',
                    element: <Reviews toggleForm={toggleForm} bd={bd} debugValue={pass}
                                      handleLink={handleLink}/>
                },
                {
                    path: '/service-areas',
                    element: <Locations bd={bd} toggleForm={toggleForm} debugValue={pass}/>
                },
                {
                    path: '/special-offers',
                    element: <SpecialOffers toggleForm={toggleForm} bd={bd}
                                            explore={[explorables.financing, explorables.warranties]}
                                            debugValue={pass}/>
                },
                {
                    path: '/about-us',
                    element: <AboutUs toggleForm={toggleForm} bd={bd}
                                      explore={[explorables.blogs, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/referral',
                    element: <ReferralIntro toggleForm={toggleForm} bd={bd}
                                            handleLink={handleLink}
                                            explore={[explorables.blogs, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/solar-estimate',
                    element: <FormEstimate toggleForm={toggleForm} bd={bd}
                                           handleLink={handleLink}
                                           explore={[explorables.blogs, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/referral/dashboard',
                    element: <ReferralDashboard toggleForm={toggleForm} bd={bd}
                                                explore={[explorables.blogs, explorables.specials]}
                                                debugValue={pass}/>
                },
                {
                    path: '/my-referral/:code',
                    element: <ReferralConfirmation toggleForm={toggleForm} bd={bd}
                                                   explore={[explorables.blogs, explorables.specials]}
                                                   debugValue={pass}/>
                },
                {
                    path: '/special-offers/financing',
                    element: <SoFinancing toggleForm={toggleForm} bd={bd}
                                          explore={[explorables.blogs, explorables.specials]}
                                          debugValue={pass}/>
                },
                {
                    path: '/special-offers/solar-special',
                    element: <SoSpecial toggleForm={toggleForm} bd={bd}
                                        explore={[explorables.blogs, explorables.specials]}
                                        debugValue={pass}/>
                },
                {
                    path: '/special-offers/solar-tax-credit',
                    element: <SoSolarTaxCredit toggleForm={toggleForm} bd={bd}
                                               explore={[explorables.blogs, explorables.specials]}
                                               debugValue={pass}/>
                },
                {
                    path: '/special-offers/battery',
                    element: <SoBattery toggleForm={toggleForm} bd={bd}
                                        explore={[explorables.blogs, explorables.specials]}
                                        debugValue={pass}/>
                },
                {
                    path: '/page/:s1',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>,

                },
                {
                    path: '/past-projects/:s1',
                    element: <Locations toggleForm={toggleForm} showForm={showForm} bd={bd}
                                        explore={[explorables.company, explorables.specials]} debugValue={pass}/>,

                },
                {
                    path: '/past-projects/:s1/',
                    element: <Locations toggleForm={toggleForm} showForm={showForm} bd={bd}
                                        explore={[explorables.company, explorables.specials]} debugValue={pass}/>,

                },
                {
                    path: '/careers/:s1',
                    element: <HomeMain toggleForm={toggleForm} showForm={showForm} debugValue={pass}/>,

                },
                {
                    path: '/careers/:s1/',
                    element: <HomeMain toggleForm={toggleForm} showForm={showForm} debugValue={pass}/>,

                },
                {
                    path: '/careers/:s1/:s2/',
                    element: <HomeMain toggleForm={toggleForm} showForm={showForm} debugValue={pass}/>,

                },
                {
                    path: '/offers/:s1/',
                    element: <SpecialOffers toggleForm={toggleForm} showForm={showForm} bd={bd}
                                            explore={[explorables.company, explorables.specials]} debugValue={pass}/>,

                },
                {
                    path: '/page/:s1/:s2',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>,

                },
                {
                    path: '/blog/:s1/:s2/',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/blog/:s1/:s2/:s3/',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/blog/:s1/:s2/:s3/:s4/',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/tag/:s1/',
                    element: <ArticlesHome toggleForm={toggleForm} showForm={showForm} bd={bd}
                                           explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/reviews/:s1/',
                    element: <Reviews toggleForm={toggleForm} showForm={showForm} bd={bd}
                                      explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/reviews/:s1/:s2/',
                    element: <Reviews toggleForm={toggleForm} showForm={showForm} bd={bd}
                                      explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
                {
                    path: '/reviews/:s1/:s2/:s3/',
                    element: <Reviews toggleForm={toggleForm} showForm={showForm} bd={bd}
                                      explore={[explorables.company, explorables.specials]} debugValue={pass}/>
                },
            ]
        }
    ])

    return bd.u_os && pass && (<RouterProvider router={router}/>);
}

export default Main;
